export const sortSessions = (sess:any[]): any[] => {
    sess = sess.sort((a:any,b:any)=>{
        if(!a?.sort_name){
            calcSessionKeys(a);
        }

        if(!b?.sort_name){
            calcSessionKeys(b);
        }

        if(a.sort_number !== undefined && b.sort_number !== undefined){
            if(a.sort_number < b.sort_number) return -1;
            if(a.sort_number > b.sort_number) return 1;
            return 0;   
        }
        if(a.sort_number !== undefined){
            return -1;
        }
        if(b.sort_number !== undefined){
            return 1;
        }
        if(a.sort_name < b.sort_name) return -1;
        if(a.sort_name > b.sort_name) return 1;
        return 0;

    })

    return sess;
}

export const calcSessionKeys = (a:any) => {
    a.sort_name = (a?.name??a?.session_name).replace(':','.').toLowerCase().split(' or ')[0];
            
    if(a?.start_time && a.start_time > '00:00:00'){
        a.sort_name = a.start_time;
        let match = a.start_time.replace(':','.').match(/(\d+\.?\d*)?/)
        if(match){
            a.sort_number = Number(match[0])
        }
    }
    if(a.sort_number) return;

    let match = a.sort_name.match(/(\d+)\s?(pm)?/);
    if(match && match[1]){
        let hrs = Number(match[1]);
        a.sort_name = a.sort_name.replace(hrs+'pm',(hrs+12)+'')
    }
    match = a.sort_name.match(/(\d+)/)
    if(match){
        a.sort_number = Number(match[0])
    }
}