<div class="gn-w-full gn-flex">
    <div class="gn-w-1/2 gn-pr-1">
        <div class="gn-w-full gn-h-full gn-rounded gn-bg-gray-300 gn-p-3">
            <form class="gn-w-full" [formGroup]="form" (ngSubmit)="updateBookit()">
                <div class="gn-flex gn-items-start">
                    <div class="gn-w-1/2 gn-pr-1">
                        <ng-select class="gn-mb-2" (change)="setVenue($event)" formControlName="venueId" [items]="venues" bindLabel="name" bindValue="venue_id" placeholder="Venue" [clearable]="false"></ng-select>
                        <ng-select class="gn-mb-2" (change)="getPackages()" formControlName="activityId" [items]="activities" bindLabel="name" bindValue="activity_id" placeholder="Activity" [clearable]="false"></ng-select>
                        <ng-select class="gn-mb-2" formControlName="packageId" bindLabel="name" bindValue="package_id" [items]="packages" placeholder="Package"></ng-select>
                        <input class="gn-w-full gn-p-2 gn-rounded gn-bg-white gn-mb-2" formControlName="sessionId" placeholder="Session ID" />
                        <ng-select class="gn-mb-2" formControlName="brandId" [items]="brands" bindLabel="text" bindValue="id" placeholder="Brand"></ng-select>
                        <ng-select class="gn-mb-2" formControlName="lang" [clearable]="false" [items]="languages" bindLabel="lang" bindValue="lang" placeholder="Language"></ng-select>
                        <input formControlName="baseUrl" class="gn-w-full gn-p-2 gn-rounded gn-bg-white gn-mb-2" placeholder="Api Url" />
                        <input formControlName="dateStr" class="gn-w-full gn-p-2 gn-rounded gn-bg-white" placeholder="Date (YYYY-MM-DD)" />
                    </div>
                    <div class="gn-w-1/2 gn-pl-1 gn-text-gray-700">
                        <div class="gn-w-full gn-flex gn-items-center gn-mb-2">
                            <div class="gn-w-1/2">
                                <label>Primary Color</label>
                            </div>
                            <div class="gn-w-1/2">
                                <input formControlName="primaryColor" type="color" />
                            </div>
                        </div>
                        <div class="gn-w-full gn-flex gn-items-center gn-mb-2">
                            <div class="gn-w-1/2">
                                <label>Accent Color</label>
                            </div>
                            <div class="gn-w-1/2">
                                <input formControlName="accentColor" type="color" />
                            </div>
                        </div>
                        <div class="gn-w-full gn-flex gn-items-center gn-mb-2">
                            <div class="gn-w-1/2">
                                <label>Text Color</label>
                            </div>
                            <div class="gn-w-1/2">
                                <input formControlName="textColor" type="color" />
                            </div>
                        </div>
                        <div class="gn-w-full gn-flex gn-items-center gn-mb-4">
                            <div class="gn-w-1/2">
                                <label>Background</label>
                            </div>
                            <div class="gn-w-1/2">
                                <input formControlName="background" type="color" />
                            </div>
                        </div>
                        <div class="gn-w-full gn-mb-2">
                            <label class="gn-flex gn-items-center gn-leading-none">
                                <input formControlName="voucher" type="checkbox" />
                                <span class="gn-ml-1">Hide Voucher Button</span>
                            </label>
                        </div>
                        <div class="gn-w-full">
                            <label class="gn-flex gn-items-center gn-leading-none">
                                <input formControlName="inline" type="checkbox" />
                                <span class="gn-ml-1">Inline Payment Form</span>
                            </label>
                        </div>
                    </div>
                </div>
                <button class="gn-bg-main gn-py-2 gn-text-lg gn-font-semibold gn-w-full gn-text-white gn-rounded gn-mt-3">Update</button>
            </form>
        </div>
    </div>
    <div class="gn-w-1/2 gn-pl-1">
        <div class="gn-w-full gn-h-full gn-rounded gn-bg-gray-300 gn-p-3">
            <div class="gn-w-full gn-h-full gn-relative">
                <div (click)="copyText()" class="gn-bg-gray-500 gn-text-gray-300 gn-flex gn-items-center gn-justify-center gn-absolute gn-top-0 gn-right-0 gn-rounded gn-p-1 gn-h-8 gn-w-8 gn-cursor-pointer">
                    <fa-icon [icon]="faCopy"></fa-icon>
                </div>
                <p style="font-family: monospace; white-space: pre-wrap" class="gn-text-gray-700 gn-text-md">
                    {{ example }}
                </p>
            </div>
        </div>
    </div>
    <textarea class="gn-invisible gn-absolute" style="left: -9999px" [value]="example" #copyEl></textarea>
</div>