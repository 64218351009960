import { HttpClient } from '@angular/common/http';
import { Injectable, ErrorHandler } from '@angular/core';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as StackTrace from 'stacktrace-js';

import { HttpService } from './http.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private http: HttpClient,
        private httpService: HttpService
    ) { }

    handleError(error: Error) {
        console.error(error);

        if (error.name === 'HttpErrorResponse') return;
        if(error.message.indexOf('Too many connections') >= 0) return; //lets not spam ourselves

        from(StackTrace.fromError(error)).pipe(
            catchError(() => of(null)),
            map(stackframes => {
                if (!stackframes) return null;
                else return stackframes.splice(0, 20).map(val => val.toString());
            }),
            switchMap(stackframes => {
                let message = [
                    `URL: ${window.location.href}\nWidget Name: Calendar Booker`,
                    `\nAPI URl: ${this.httpService.baseURL}\nError Message: ${error.message} `,
                    `\nUA: ${window.navigator.userAgent}`
                ].join('');

                if (stackframes) message += `\`\`\`${stackframes.join('\n')}\`\`\``;

                if (window.origin === 'http://localhost:4200') return;

                return this.http.get('https://apidev2.geronigo.com/en/action_query/slack/outOfMemory', {
                    observe: 'response',
                    params: { no_intercept: '1', channel: '#widgeterrors', message }
                });
            })
        ).subscribe({next:() => {}, error:() => {}});
    }
}
