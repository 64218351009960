import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { HttpService } from '../services/http.service';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private http: HttpService,
        private toastr: ToastrService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has('X-API-KEY') || req.params.has('no_intercept') || req.url.endsWith('.json')) return next.handle(req);

        return this.http.getPublicKey().pipe(
            switchMap(token => {
                const request = req.clone({
                    headers: req.headers.set('X-API-KEY', token)
                });

                return next.handle(request);
            }),
            switchMap(event => {
                if (
                    event instanceof HttpResponse && (typeof event.body === 'string' || (req.method === 'POST' && !event.body))
                ) {
                    return throwError(()=>event);
                }

                return of(event);
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 || error.status === 403 || req.params.has('no_intercept_response')) return throwError(()=>error);

                if (error.error && error.error.errors) error.error.errors.forEach(item => this.toastr.error(item.message));
                else this.toastr.error('Unexpected error occured');

                return throwError(()=>error);
            })
        );
    }
}
