import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError, timer } from 'rxjs';
import { switchMap, shareReplay, retryWhen, mergeMap, retry } from 'rxjs/operators';
//import * as moment from 'moment';

import { HttpConfig } from '../models/http-config.model';
import { MemoryStorage } from './memory-storage.service';
import { format } from 'date-fns';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    public baseURL = 'https://api.rezbot.com/api/v1.1/';
    public publicKey: Observable<string>;
    public appKey = 'geronigobookit';
    public apiKey = 'rezbotbookit';
    public mode: 'live'|'test' = 'live';
    public extraParams: {[key: string]: string} = {};
    public lang = 'en';

    constructor(
        private http: HttpClient,
        private memoryStorage: MemoryStorage
    ) { }

    public get isTestMode(): boolean {
        return this.mode !== 'live';
    }

    public get isDev(): boolean {
        return this.baseURL.indexOf('apidev') >=0;
    }

    public setApi(version: string): void {
        const versions = [
            {
                name: 'live',
                url: 'https://api.rezbot.com/api/v1.1/'
            },
            {
                name: 'stage',
                url: 'https://apistage.rezbot.com/api/v1.1/'
            },
            {
                name: 'dev1',
                url: 'https://apidev.rezbot.com/api/v1.1/'
            },
            {
                name: 'dev2',
                url: 'https://apidev2.rezbot.com/api/v1.1/'
            },
            {
                name: 'local',
                url: 'https://geronigo.djanes/api/v1.1/'
            }
        ];

        const url = window.location.href;
        const defaultVersion = url.includes('gitlab') || url.includes('localhost') ? 'stage' : 'live';

        let api: { name: string, url: string };

        if (version) api = versions.find(item => item.name === version);
        if (!version || !api) api = versions.find(item => item.name === defaultVersion);

        this.baseURL = api.url;
    }

    public getPublicKey(): Observable<string> {
        if (this.publicKey) return this.publicKey;

        const key = this.getKeyFromMemoryStorage();

        if (key) return of(key);

        this.publicKey = this.http.get<any>(`${this.baseURL}/auths?access=public`, {
            observe: 'response',
            headers: {
                'X-API-KEY': this.apiKey,
                'X-APP-KEY': this.appKey
            }
        }).pipe(
            switchMap(data => {
                this.memoryStorage.setItem('publicKey', data.body.auths.jwt);

                return of(data.body.auths.jwt);
            }),
            shareReplay(1)
        );

        return this.publicKey;
    }

    public get(url: string, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.get<any>(`${this.baseURL}${url}`, {
            observe: 'response', ...this.parseConfig(config)
        }).pipe(
            retry({count:2, delay:(err, i) => {
                    if (i > 2 || err.status !== 401) return throwError(()=>err);

                    this.memoryStorage.removeItem('publicKey');
                    this.publicKey = undefined;

                    return timer(2000 * i);
                }})

        );
    }

    public post(url: string, body: any, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.post<any>(`${this.baseURL}${url}`, body, {
            observe: 'response', ...this.parseConfig(config)
        }).pipe(
            retry({delay:(err, i) => {
                if (i > 2 || err.status !== 401) return throwError(()=>err);

                this.memoryStorage.removeItem('publicKey');
                this.publicKey = undefined;

                return timer(2000 * i);
            }})
        );
    }

    public patch(url: string, body: object, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.patch<any>(`${this.baseURL}${url}`, body, {
            observe: 'response', ...this.parseConfig(config)
        });
    }

    public put(url: string, body: object, config: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.put<any>(`${this.baseURL}${url}`, body, {
            observe: 'response', ...this.parseConfig(config)
        }).pipe(
            retry({delay:(err, i) => {
                if (i > 2 || err.status !== 401) return throwError(()=>err);

                this.memoryStorage.removeItem('publicKey');
                this.publicKey = undefined;

                return timer(2000 * i);
            }})
        );
    }

    public delete(url: string, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.baseURL}${url}`, {
            observe: 'response', ...this.parseConfig(config)
        });
    }

    private parseConfig(config?: HttpConfig): HttpConfig {
        const newConfig: HttpConfig = {
            headers: { 'X-APP-KEY': this.appKey },
            params: { lang: this.lang, lang_single: '1' }
        };

        if (!config) return newConfig;

        if (config.params) newConfig.params = { ...newConfig.params, ...this.filterParams(config.params) };
        if (config.headers) newConfig.headers = { ...newConfig.headers, ...this.filterParams(config.headers) };

        return newConfig;
    }

    private filterParams(params: { [key: string]: any }): any {
        const data: any = {};

        Object.keys(params).forEach(key => {
            if (!params.hasOwnProperty(key) || !params[key]) return;

            if (params[key] === true || params[key] === false) data[key] = params[key] ? '1' : '0';
            //else if (params[key] instanceof Date) data[key] = moment(params[key], 'YYYYMMDD');
            else if (params[key] instanceof Date) data[key] = format(params[key], 'yyyyMMdd');
            else data[key] = params[key];
        });

        return data;
    }

    private getKeyFromMemoryStorage(): string {
        const key = this.memoryStorage.getItem('publicKey');

        try {
            JSON.parse(key);

            return null;
        } catch (err) {
            return key;
        }
    }

    public enocodeJSON(element: any, key?: string, list?: any[]): string {
        list = list || [];

        if (typeof (element) === 'object') {
            for (const idx in element) this.enocodeJSON(element[idx], key ? key + '[' + idx + ']' : idx, list);
        } else {
            list.push(key + '=' + encodeURIComponent(element));
        }

        return list.join('&');
    }
}
