import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import  cloneDeep  from "lodash/cloneDeep"
import { ToastrService } from 'ngx-toastr';
import { Observable, merge, scheduled, asyncScheduler, of } from 'rxjs';
import { catchError, finalize, mergeAll, takeLast, toArray } from 'rxjs/operators';

import { HttpService } from 'src/app/services/http.service';

@Component({
    selector: 'app-booking-fields',
    templateUrl: './booking-fields.component.html'
})
export class BookingFieldsComponent implements OnInit {
    @Input() package: any;
    @Input() priceOptions: any[] = [];
    @Input() primaryColor: string;
    @Input() backgroundColor: string;
    @Input() accentColor: string;
    @Input() textColor: string;
    @Input() booking: any;
    @Input() brand: any;

    public fieldsLoading = true;
    public bookingFields: any[] = [];
    public participantsFields: any[] = [];
    public participant: any;
    public participantIndex: number;
    public loading = false;
    public success: string = '';

    constructor(
        private http: HttpService,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        this.setBookingFields();
    }

    public get hint(): string {
        const group = this.bookingFields.find(item => item.value === '');
        const participant = this.participantsFields.find(item => !item.completed);

        if (participant) return `Complete ${participant.title}`;
        if (!group) return 'Fill in all fields';

        return `Fill in ${group.name}`;
    }

    public get participantHint(): string {
        const field = this.participant.fields.find(item => item.value === '');

        if (!field) return 'Fill in all fields';

        return `Fill in ${field.name}`;
    }

    public get participantsCompleted(): boolean {
        return this.participantsFields.length === this.participantsFields.filter(item => item.completed).length;
    }

    public saveParticipantFields(): void {
        this.participantsFields[this.participantIndex] = { ...this.participant, completed: true };

        this.participant = null;
        this.participantIndex = null;
    }

    public saveFields(): void {
        if (this.loading) return;
        this.loading = true;

        const headers = { 'X-API-KEY': this.booking.guest_key };

        scheduled([
            this.http.put('/bookings', {
                booking_id: this.booking.booking_id,
                booking_fields: this.bookingFields
            }, { headers }),
            ...this.participantsFields.map((item, i) => {
                if(item.submitted) return of(null);

                let participant_id = item?.participant_id || (this.booking.participants[i] || null)?.participant_id

                let participantData: any = {
                    booking_id: this.booking.booking_id,
                    name: item.title || i > 0 ? ('Participant '+(i+1)) : this.booking.customer_name,
                    booking_fields: item.fields || []
                };
                if(participant_id) participantData.participant_id = participant_id;

                return this.http[participant_id ? 'put' : 'post']('/participants', participantData, { headers }).pipe(
                    catchError(e=>{
                        return of(e)
                    })
                );
            })
        ],asyncScheduler).pipe(
            mergeAll(1),
            toArray(),
            finalize(() => this.loading = false)
        ).subscribe(
        {next:(ress)=>{
            //this should stop the repost of multiple elements ?
            this.participantsFields.map((item, i) => {

                if(ress[i+1] && (ress[i+1]?.error)){
                    item.error = true;
                }

                if(ress[i+1] && (ress[i+1]?.body?.participants||[]).length > 0 && ress[i+1]?.body?.participants[0]?.participant_id){
                    item.participant_id = ress[i+1].body.participants[0].participant_id;
                    item.submitted = true;
                    item.error = false;
                }
            });
        },
        complete:() => {
            //check that participantsFields is empty or completed
            if(this.participantsFields.filter(item=>!item?.submitted).length === 0)
                this.redirect()
            else this.toastr.error('Some Extra Information is Required. Click the Edit button');
        }
        });
    }

    public fillFields(fields: any, index: number): void {
        this.participant = fields;
        this.participantIndex = index;
    }

    private setBookingFields(): void {
        const qty = this.priceOptions.reduce((acc, item) => acc + (item.qty_requested || 0), 0);
        const people: string[] = this.priceOptions.reduce((acc, item) => ([
            ...acc, ...Array(item.qty_requested).fill(item.name)
        ]), []);

        //console.log(this.package);

        const fields = (this.package?.booking_fields || []).map(item => {
            const inputName = item.name.replace(/\s/g, '_').toLowerCase();

            if (item.type === 'list') return { ...item, value: null, inputName };
            if (item.type === 'bool') return { ...item, value: false, inputName };

            return { ...item, value: '', type: item.type || 'text', inputName };
        }).filter(item => {
            if (item.type === 'list' && (!item.opt || !item.opt.length)) return false;

            return true;
        });

        const bookingFields = fields.filter(item => +item.b);
        const participantFields = fields.filter(item => +item.p);

        this.bookingFields = bookingFields;

        if (!participantFields.length) return;

        let lastField = people[0];

        for (let index = 0, counter = 1; index < qty; index++, counter++) {
            if (lastField !== people[index]) counter = 1;
            lastField = people[index];

            const pFields = cloneDeep(participantFields).filter(field => {
                return !field.price_option || field.price_option === people[index];
            });

            if (!pFields.length) continue;

            this.participantsFields.push({
                title: `${people[index]} ${counter}`,
                group: people[index].toLowerCase().replace(/ /g, '_'),
                completed: false,
                fields: pFields
            });
        }

        if (!this.bookingFields.length && !this.participantsFields.length) this.redirect();
        else this.fieldsLoading = false;
    }

    private redirect(): void {
        let url = `https://rezbot.com/${this.booking.guest_key}/${this.booking.booking_id}`;

        if (this.booking.company_id == 2) {
	        const themeUrl = this.brand && this.brand.theme_website ? this.brand.theme_website : 'https://geronigo.com';
	        url = `${themeUrl}/en/confirmation/bookingID-${this.booking.booking_id}_guestKey-${this.booking.guest_key}`;
        }

        if(this.http.isDev || this.http.isTestMode){
            this.fieldsLoading = false;
            console.log('no redirect to '+url);
            this.success = "Payment complete. would redirect to url";
            return;
        }

        window.location.href = url;
    }
}
