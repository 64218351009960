import { BrowserModule, } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, DoBootstrap } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
//import { CalendarDateFormatter, CalendarModule, CalendarMomentDateFormatter, DateAdapter, MOMENT } from 'angular-calendar';
import { CalendarDateFormatter, CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
//import * as moment from 'moment';
//import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { DAYS_OF_WEEK } from 'angular-calendar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';
import { ClipboardModule } from '@angular/cdk/clipboard';


import { LOCAL_STORAGE_PROVIDERS } from './services/local-storage.service';
import { BookingDetailsComponent } from './components/booking-details/booking-details.component';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { BookitComponent } from './components/bookit/bookit.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { BookingPaymentComponent } from './components/booking-payment/booking-payment.component';
import { SettingsComponent } from './components/settings/settings.component';
import { BookingFieldsComponent } from './components/booking-fields/booking-fields.component';
import { GlobalErrorHandler } from './services/global-error-handler.service';
import { PaymentService } from './services/payment.service';

/*moment.updateLocale('en', {
    week: { dow: DAYS_OF_WEEK.MONDAY, doy: 0 }
});*/

const MY_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'https://apidev.rezbot.com/config/lang/', '.json');
}

const components = [
    BookingDetailsComponent,
    CustomerDetailsComponent,
    BookitComponent,
    BookingPaymentComponent,
    SettingsComponent,
    BookingFieldsComponent
];

@NgModule({ declarations: components,
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        } /*, {
          dateFormatter: {
              provide: CalendarDateFormatter,
              useClass: CalendarMomentDateFormatter
          }
      }*/),
        NgSelectModule,
        ToastrModule.forRoot({ timeOut: 5000 }),
        FontAwesomeModule,
        TooltipModule,
        ClipboardModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatDatepickerModule,
        MatNativeDateModule], providers: [
        LOCAL_STORAGE_PROVIDERS,
        MatDatepickerModule,
        PaymentService,
        //{ provide: MOMENT, useValue: moment },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule implements DoBootstrap {
    constructor(private injector: Injector) { }

    ngDoBootstrap() {
        const elements: any[] = [
            [BookitComponent, 'geronigo-bookit'],
            [BookitComponent, 'rezbot-bookit'],
            [SettingsComponent, 'settings-component'],
        ];

        for (const [component, name] of elements) {
            if (customElements.get(name)) continue;

            const el = createCustomElement(component, { injector: this.injector });

            customElements.define(name, el);
        }
    }
}
