<div class="rezbot-calendar-bookit gn-p-2" [ngStyle]="height ? { height: height + 'px', position: 'relative' } : { }"  [style.background-color]="backgroundColor" [style.padding]="'5px'" >
    <div class="gn-h-full" 
    [ngClass]="mode==='test' ? 'gn-border-red-300 gn-border-2':''"
    [class.gn-overflow-scroll]="height" id="rezbot-scroller" (scroll)="checkHeight()">
        <div class="gn-w-full gn-bg-red-100 gn-text-center gn-text-red-500 gn-font-bold" *ngIf="mode==='test'"
        [tooltip]="mode==='test'? 'Card Payments will be faked.<br/>NO MONEY WILL BE COLLECTED.<br/>This mode should not use for customer bookings':''">!! TEST MODE !!</div>
        <div class="gn-w-full gn-p-5" [class.gn-hidden]="step">
            <div class="gn-w-32 gn-h-32 gn-mx-auto gn-rounded-full gn-border-t-2 gn-border-r-2 gn-border-solid gn-spin" [style.border-color]="primaryColor"></div>
        </div>
        
        <div class="gn-w-full gn-text-center gn-py-10" *ngIf="step === 'error'">
            <p class="gn-leading-none gn-text-xl gn-m-0" [style.color]="textColor">Venue couldn't be loaded</p>
        </div>
        
        <div class="gn-w-full" [class.gn-hidden]="!step">
            <div class="gn-w-full gn-mb-2 gn-px-3 gn-py-2 gn-flex gn-items-center gn-justify-center gn-rounded" [style.background]="accentColor" [style.color]="textColor" [class.gn-hidden]="!package && !session && !date">
                <p class="gn-font-semibold gn-m-0 gn-mr-4 gn-text-base">{{ package?.name }}</p>
                <p class="gn-font-semibold gn-m-0 gn-mr-4 gn-text-base">{{ session?.price }}</p>
                <p class="gn-font-semibold gn-m-0 gn-mr-4 gn-text-base">{{ date ? (date | date: 'dd MMMM yyyy') : '' }}</p>
                <p class="gn-font-semibold gn-m-0 gn-text-base">{{ session?.session_name }}</p>
            </div>
        
            <div class="gn-w-full" *ngIf="step === 'booking'">
                <app-booking-details
                    [venue]="venue"
                    [date]="date"
                    [viewDate]="viewDate"
                    [primaryColor]="primaryColor"
                    [accentColor]="accentColor"
                    [textColor]="textColor"
                    [package]="package"
                    [sessionId]="sessionId"
                    [activityId]="activityId"
                    (updateField)="updateField($event)"
                    (buyVoucher)="buyVoucher.emit()"
                ></app-booking-details>
            </div>

            <div class="gn-w-full" *ngIf="step === 'customer'">
                <app-customer-details
                    [loading]="enquiryLoading"
                    [primaryColor]="primaryColor"
                    [accentColor]="accentColor"
                    [textColor]="textColor"
                    [session]="session"
                    [venue]="venue"
                    [extras]="extras"
                    [package]="package"
                    [formSettings]="form"
                    [inline]="inline"
                    [booking]="booking"
                    [payProfiles]="payProfiles"
                    [groupTypes]="groupTypes"
                    [company]="company"
                    [brandId]="brandId"
                    (updateField)="updateField($event)"
                    (bookNow)="book($event)"
                    (enquire)="submitEnquiry($event)"
                ></app-customer-details>
            </div>

            <div class="gn-w-full" *ngIf="step === 'payment'">
                <app-booking-payment
                    [venue]="venue"
                    [package]="package"
                    [extras]="extras"
                    [date]="date"
                    [session]="session"
                    [company]="company"
                    [primaryColor]="primaryColor"
                    [accentColor]="accentColor"
                    [textColor]="textColor"
                    [priceOptions]="priceOptions"
                    [booking]="booking"
                    [brand]="brand"
                    [termsText]="terms"
                    [activities]="activities"
                    [vouchers]="vouchers"
                    [promo]="promo"
                    [duration]="+duration"
                    [message]="message"
                    [affiliateId]="affiliateId"
                    (tostart)="step = 'booking'"
                    (updateField)="updateField($event)"
                    (setStep)="step = 'booking_fields'"
                ></app-booking-payment>
            </div>
    
            <div class="gn-w-full" *ngIf="step === 'booking_fields'">
                <app-booking-fields
                    [package]="package"
                    [primaryColor]="primaryColor"
                    [accentColor]="accentColor"
                    [textColor]="textColor"
                    [priceOptions]="priceOptions"
                    [brand]="brand"
                    [booking]="booking"
                ></app-booking-fields>
            </div>
        </div>
    </div>

    <div
        style="background: linear-gradient(0deg, rgba(255,255,255,1) 40%, rgba(0,148,92,0) 100%);"
        class="gn-w-full gn-py-2 gn-flex gn-items-center gn-justify-center gn-cursor-pointer gn-absolute gn-bottom-0 gn-left-0 gn-right-0"
        (click)="scrollToBottom()"
        *ngIf="showArrow"
    >
        <fa-icon [style.color]="textColor" [icon]="chevronDown"></fa-icon>
    </div>
</div>