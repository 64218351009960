import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { Subscription } from 'rxjs';

import { Availability, Package, Venue } from 'src/app/models/venues.model';
import { PaymentService } from 'src/app/services/payment.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
    selector: 'app-customer-details',
    templateUrl: './customer-details.component.html',
})
export class CustomerDetailsComponent implements OnInit, OnDestroy, OnChanges {
    @Input() loading = false;
    @Input() primaryColor: string;
    @Input() backgroundColor: string;
    @Input() accentColor: string;
    @Input() textColor: string;
    @Input() session: Availability;
    @Input() venue: Venue;
    @Input() package: any;
    @Input() payProfiles: any;
    @Input() groupTypes: {[key:string]:string};
    @Input() formSettings: { field: string, value?: string, lock?: boolean }[] = [];
    @Input() inline: string;
    @Input() extras: Package;
    @Input() booking: any;
    @Input() company: any;
    @Input() brandId: any;

    @Output() updateField: EventEmitter<{ field: string, value: any }> = new EventEmitter();
    @Output() bookNow: EventEmitter<{ form: any, priceOptions: any[] }> = new EventEmitter();
    @Output() enquire: EventEmitter<{ form: any, priceOptions: any[] }> = new EventEmitter();

    public faChevronLeft = faChevronLeft;
    public faPlus = faPlus;
    public faMinus = faMinus;
    public faLock = faLock;
    public form: UntypedFormGroup;
    public people = 0;
    public availMessage = 'blah';
    public availStatus = '';

    public chatbotFeature = false;

    public morePaxNeeded = 0;


    private availabilities: any[] = [];
    private sessionsForExtras: any = {};
    //public extras: any[] = [];

    private formSub: Subscription;

    @ViewChild('groupDetailsEl') groupDetailsRef: ElementRef;
    @ViewChild('chatbotEl') chatbotRef: ElementRef;

    constructor(
        private builder: UntypedFormBuilder,
        private payment: PaymentService,
        private http: HttpService
    ) { }

    ngOnInit() {
        //change all the options
        //console.log('init', this.morePaxNeeded, this.people, this.booking);

        if(this.booking){
            this.people = this.booking.qty_requested;
        }
        /*this.session.price_options.forEach(opt=>{
                this.changeOption(opt,0);
                console.log('init', this.morePaxNeeded, this.people);
            }
        );*/
        //console.log('init', this.morePaxNeeded, this.people);

        this.payment.init(this.venue?.pay_profile, this.payProfiles);
        if(this?.booking?.booking_id === undefined){
            this.availStatus = this.session.avail_status;
            if(this.session.avail_status == 'limited'){
                    if(this.venue.company_id == '2')
                        this.availMessage = 'Limited Availability';
                    else
                        this.availMessage = 'Limited Availability. Please call before booking';
            }else{
                    this.availMessage = '';
            }
        }else{
            this.availMessage = 'Booking Exists';
        }

        let first_name = this.booking?.customer_name&&this.booking.customer_name.split(' ')[0];
        let last_name = this.booking?.customer_name&&this.booking.customer_name.split(' ').splice(1,999);

        this.form = this.builder.group({
            first_name: [first_name??''],
            last_name: [last_name??''],
            customer_email: [this.booking?.customer_email??''],
            customer_tel: [this.booking?.customer_tel??''],
            title: [this.booking?.title??''],
            group_type: [null],
            message: [''],
            subscribed: [false]
        });

        this.calculateExtras();

        setTimeout(()=>this.groupDetailsRef.nativeElement.scrollIntoView(),200);

        if(this.company.company_id == '2'){
            this.chatbotFeature = true;
        }
        setTimeout(()=>{
            if(this.chatbotFeature){
                if(this.chatbotRef && this.chatbotRef?.nativeElement){
                    this.chatbotRef.nativeElement.addEventListener('askQuestion',(a)=>this.askQuestion(a));
                }
            }
        },1000)
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.formSettings && changes.formSettings.currentValue) this.setForm();
    }

    ngOnDestroy() {
        if (this.formSub) this.formSub.unsubscribe();
    }

    public get hint(): string {
        if (this.form.get('first_name').invalid) return 'hints.fill_in_name';
        if (this.form.get('customer_email').invalid) return 'hints.provide_valid_email';
        if (this.form.get('customer_tel').invalid) return 'hints.phone';
        if (this.morePaxNeeded) return this.morePaxNeeded+' more Participants Needed';

        return '';
    }

    public get apiKey(): string {
        return this.http.apiKey;
    }
    public get appKey(): string {
        return this.http.appKey;
    }

    public setForm(): void {
        this.formSettings.forEach(item => {
            const control = this.form.get(item.field);

            if (item.value) control.setValue(item.value);
            if (item.lock) control.disable();
        });
    }

    public book(): void {
        this.bookNow.emit({ form: this.form.getRawValue(), priceOptions: this.session.price_options });
    }

    public enquiry(): void {
        this.enquire.emit({ form: this.form.getRawValue(), priceOptions: this.session.price_options });
    }

    public changeOption(option: any, amount: number): void {
        let maxPax = +option.max_pax || null;

        const ignorePax = false;
        const minPax = +option.min_pax || +this.package.min_pax;
        const newQty = this.people + amount;

        if (this.session.max_pax && (!maxPax || maxPax > +this.session.max_pax)) maxPax = +this.session.max_pax;

        if (!ignorePax && maxPax && maxPax < newQty) return;

        if (option.qty_requested + amount < 0) return;
        //else if (minPax && newQty < minPax && amount > 0 && !ignorePax) amount = minPax;
        else if (minPax && newQty < minPax && amount > 0 && !ignorePax){
            //this.morePaxNeeded = minPax - amount;
            amount = amount; //increase it as expected
        } 
        //else if (minPax && this.people === minPax && amount < 0 && !ignorePax) amount = -minPax;
        /*else if (minPax && this.people === minPax && amount < 0 && !ignorePax){ 
            amount = amount;
            //this.morePaxNeeded = - (minPax - amount);
        }*/
        else if (maxPax && option.qty_requested === maxPax && amount > 0) return;
        if (option.qty_requested + amount < 0) return;

        this.morePaxNeeded = 0;

        option.qty_requested += amount;
        if(newQty < minPax && newQty != 0){
            this.morePaxNeeded = minPax - newQty;
        }

        this.setAltsPrices(this.people + amount);
    }

    public changeExtra(option: any, amount: number): void {
        let maxPax = +option.max_pax || null;

        if(option.qty_requested == undefined) option.qty_requested = 0;

        const ignorePax = false;
        const minPax = +option.min_pax || 0;

        if (this.session.max_pax && (!maxPax || maxPax > +this.session.max_pax)) maxPax = +this.session.max_pax;

        if (option.qty_requested + amount < 0) return;
        else if (minPax && this.people === minPax && amount < 0 && !ignorePax) amount = -minPax;
        else if (maxPax && option.qty_requested === maxPax && amount > 0) return;
        if (option.qty_requested + amount < 0) return;

        option.qty_requested += amount;

        this.updateField.emit({ field: 'extras', value: this.extras });
    }

    private setAltsPrices(qty: number): void {
        this.session.price_options = this.session.price_options.map(option => {
            if (!option.alts || !option.alts.length) return option;

            const price = option.alts.reduce((a, opt) => {
                const val = qty || 1;
                return a = +opt.min_pax <= val ? +opt.price : a;
            }, 0);

            if (price <= 0) option.price = option._price;
            else option.price = price;

            return option;
        });

        this.people = qty;

        this.updateField.emit({ field: 'session', value: this.session });
    }


    public calculateExtras(){
        //let extras = this.venue.extras.filter(item=>this.session.extras.find(it=>it == +item.package_id));
        let extras = this.extras;

        let session_ids = this.session?.session_id ? [this.session?.session_id] : [];
        session_ids = [...new Set(session_ids.reduce((acc,val) => acc.concat(val,[]),[]))];

        this.sessionsForExtras = {};

        this.availabilities = [this.session];

        this.extras = extras.filter(it=>/*it.capacity === null || it.capacity==='' ||*/ this.availabilities.filter(item=>{ 
            if(item.session_id != this.session.session_id) return false;
            if(it.package_id==item.package_id || item.package_ids && item.package_ids.find(it2=>it2 == it.package_id)){
                this.sessionsForExtras[it.package_id] = item;
                if(item.max_group === 0 || item.max_pax != '' && item.max_pax !== null && it.qty_requested > item.max_pax){
                    //set it to 0
                    it.qty_requested = 0; it.price_options.map(itpo=>itpo.qty_requested=0);
                }
                if(item.max_pax == 0 || item.max_group == 0) return false;
                return true;
            }
            return false;
        }));
    }

    public askQuestion(e): void {
        this.form.get('message').setValue(e.detail);
    }
}
