import { Component, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, OnInit, OnDestroy, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {Clipboard} from '@angular/cdk/clipboard';

import { ToastrService } from 'ngx-toastr';
import { of, throwError, Observable, from, combineLatest, concat, Subscription, interval, timer } from 'rxjs';
import { tap, switchMap, finalize, catchError, last } from 'rxjs/operators';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { HttpErrorResponse } from '@angular/common/http';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';
import  round  from "lodash/round"

import { HttpService } from 'src/app/services/http.service';
import { applyPromo } from 'src/app/helpers/price-options.helpers';
import { PaymentService } from 'src/app/services/payment.service';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { MemoryStorage } from 'src/app/services/memory-storage.service';
import { addDays } from 'date-fns/addDays';
import { addWeeks  } from 'date-fns/addWeeks'; 
import { format } from 'date-fns/format'; 
import { isSameDay } from 'date-fns/isSameDay';
import { parse } from 'date-fns/parse';
import { startOfDay } from 'date-fns/startOfDay';
import { isBefore } from 'date-fns/isBefore';
import { isAfter } from 'date-fns/isAfter';
import { isEqual } from 'date-fns/isEqual';

declare var paypal: any;
//declare var fbq: any;

@Component({
    selector: 'app-booking-payment',
    templateUrl: './booking-payment.component.html',
})
export class BookingPaymentComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @ViewChild('groupPaymentEl') groupPaymentRef: ElementRef // to scroll into view
    @ViewChild('paypalEl', { static: true }) paypalEl: ElementRef;
    @ViewChild('paymentRequestEl', { static: true }) paymentRequestEl: ElementRef;
    @ViewChild('cardEl', { static: true }) cardEl: ElementRef;

    @Output() updateField: EventEmitter<{ field: string, value: any }> = new EventEmitter();
    @Output() setStep: EventEmitter<void> = new EventEmitter();
    @Output() tostart: EventEmitter<void> = new EventEmitter();

    @Input() duration: number;
    @Input() message: number;
    @Input() venue: any;
    @Input() package: any;
    @Input() extras: any;
    @Input() date: Date;
    @Input() session: any;
    @Input() priceOptions: any[] = [];
    @Input() activities: any[] = [];
    @Input() primaryColor: string;
    @Input() backgroundColor: string;
    @Input() accentColor: string;
    @Input() textColor: string;
    @Input() booking: any;
    @Input() brand: any;
    @Input() company: any;
    @Input() promo: any;
    @Input() vouchers: any[] = [];
    @Input() termsText: any;
    @Input() affiliateId: string;

    public total = 0;
    public fee = 0;
    public form: UntypedFormGroup;
    public showCard = false;
    public loading = false;
    public modalVisible = false;
    public availLoading = false;
    public promoLoading = false;
    public faChevronLeft = faChevronLeft;
    public faCopy = faCopy;
    public processor: string;
    public timer: string;
    public showPaymentRequest = false;
    public showTerms = false;
    public dueBy: string;
    public options: any = [];
    public option = 'full';
    public promocode = '';
    public paymentMethods = 0;
    public flexi = 0;
    public clickAllowed :boolean = true;

    public deposit_fix = true;

    private paymentRequestInstance: any;
    private paymentButton: any;
    private timerSub: Subscription;
    private card: any;

    public PPpayLater: boolean = false;

    constructor(
        private http: HttpService,
        private builder: UntypedFormBuilder,
        private toastr: ToastrService,
        private payments: PaymentService,
        private memoryStorage: MemoryStorage
    ) { }

    ngOnInit() {
        this.setFlexiPrice();

        this.form = this.builder.group({
            name: [this.http.isTestMode?'Test:':'', Validators.required],
            email: [this.booking.customer_email, [Validators.required]],
            address: [this.http.isTestMode?'test':'', Validators.required],
            card_zip: [this.http.isTestMode?'12345':'', Validators.required],
            terms: [false, Validators.requiredTrue],
            flexi: [false]
        });

        if (this.duration) this.startTimer();
        if (this.affiliateId) this.applyPromoVoucher('promocode', this.affiliateId, false);

        setTimeout(()=>this.groupPaymentRef.nativeElement.scrollIntoView(),200)
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.priceOptions && changes.priceOptions.currentValue) || changes.vouchers) this.getTotals();
    }

    ngAfterViewInit() {
        this.initPayments();
    }

    ngOnDestroy() {
        if (this.card) {
            // this.card.removeEventListener('change', this.cardHandler);
            this.card.destroy();
        }

        if (this.timerSub) this.timerSub.unsubscribe();
        if (this.paymentButton) this.paymentButton.destroy();
    }

    public get profiles(): string[] {
        return this.payments.profiles;
    }

    public get selectedOption(): any {
        if (!this.option) return;

        return this.options.find(item => item.val === this.option);
    }

    public get filteredExtras(): any[] {

        return this.extras.filter(it=>it.qty_requested>0);
    }

    public get hint(): string {
        if (this.form.get('name').invalid) return 'hints.fill_in_name';
        if (this.form.get('email').invalid) return 'hints.provide_valid_email';
        if (this.form.get('address').invalid) return 'hints.address';
        if (this.form.get('card_zip').invalid) return 'hints.card_zip';
        if (this.form.get('terms').invalid) return 'hints.terms';
        return '';
    }

    public get dueNow(): number {
        /*if(this.http.isDev){
            return round(1+Math.random(),2);
        }*/

        let total = this.selectedOption ? this.selectedOption.price + this.fee : this.total + this.fee;

        if (this.vouchers.length) total -= +this.vouchers.reduce((acc, item) => acc + +item.balance_value, 0);
        if (total < 0) total = 0;

        return round(total, 2);
    }

    public toggleCard(): void {
        this.showCard = !this.showCard;
    }

    public resetTimer(): void {
        if (this.availLoading) return;
        this.availLoading = true;

        timer(2000).pipe(
            finalize(() => this.availLoading = false)
        ).subscribe(() => this.startTimer());
    }

    public makeTransaction(token?: { id: string }, paymentMethod = 'card', ev?: any, force = false): void {
        if (this.loading && !force) return;
        this.loading = true;

        const form = this.form.getRawValue();
        const headers = { 'X-API-KEY': this.booking.guest_key };
        let transaction: any;

        let combined_params:Observable<any>[] = [
            this.http.put('/bookings', {
                booking_id: this.booking.booking_id,
                originally: 'booking'
            }, { headers }),
            ...this.booking.components.map(item => {
                let optionNames = [];
                if(item.price_options && item.price_options.length > 0){
                    optionNames = item.price_options.map(val => val.name);
                }

                return this.http.put('/components', {
                    ...item,
                    price_options: this.priceOptions.filter(val => optionNames.includes(val.name)),
                    promo_id: this.promo ? this.promo.promo.promo_id : null
                }, { headers });
            })
        ];
        if( this.flexi > 0 && form.flexi){

        combined_params.push(
           this.http.post('/components', {
                booking_id: this.booking.booking_id,
                start_date: this.booking.start_date,
                name: 'Flexi Date',
                price: this.flexi,
                extra: 1,
                qty_requested: 1,
                skip_check: 1
            }, { headers }));
        }
        combineLatest(combined_params).pipe(
            switchMap(() => this.voucherTransaction()),
            switchMap(() => token ? of(token) : this.getToken()),
            switchMap(res => {
                if (!res) return of(null);

                //console.log('post');

                return this.http.post('/transactions', {
                    booking_id: this.booking.booking_id,
                    payment_method: paymentMethod,
                    portal_ref: res.id,
                    total_amount: this.dueNow,
                    transaction_charge: this.fee,
                    portal_uid: paymentMethod === 'card' ? this.payments.stripeKey : this.payments.paypalKey,
                    name: form.name,
                    email: form.email,
                    note: form.note || null,
                    address: form.address,
                    card_zip: form.card_zip,
                    save: 1,
                    currency: this.booking.currency,
                    source: 'widget.calendar-booker.public',
                    status: this.session?.i_id ? 'captured' : 'pending',  //i_id is rezdy,...
                    _return: 'booking',
                    //request_3d: 'true',
                    test:this.http.isTestMode?1:0
                }, { headers });
            }),
            switchMap(res => {
                //console.log('switchmap',res);
                if (!res) return of(null);

                transaction = res.body.transactions[0];
                if (ev) ev.complete('success');

                if (!res.body['3d_secure'] || res.body['3d_secure'].action === 'success') return of(res);

                return this.payments.stripe.handleCardAction(res.body['3d_secure'].payment_intent_client_secret);
            }),
            switchMap((res: any) => {
                //console.log('switchmap',res, ev);
                if (!res){
                    if(ev) ev.complete('success');
                    return of(null);
                }

                if (res.error){
                    //console.log('switchmap res fail', res.error);
                    if(ev){ 
                        ev.complete('fail');
                    }
                    return throwError(()=>({ type: 'card_error', message: res.error.message }));
                } 

                if(ev) ev.complete('success');
                if (res.status) {
                    return of(res);
                }

                return this.http.post('/transactions', { ...transaction, _return: 'booking' }, { headers });
            }),
            catchError(err => {
                //console.log('catchError fail', err, ev);
                if (err.type || err instanceof HttpErrorResponse){
                    if(ev){
                        ev.complete('fail');
                    }
                    return throwError(()=>err);
                } 

                return of(null);
            }),
            finalize(() => this.loading = false)
        ).subscribe({next: (it)=> {
            //console.log(ev, it, 'success1');
            if (ev) ev.complete('success');

            this.memoryStorage.removeItem('wcs-booking');

            // tslint:disable-next-line: no-string-literal
            window['dataLayer'].push({
                event: 'conversion_booking',
                conversion_type: 'booking',
                order_id: `${this.booking.booking_id}_${transaction ? transaction.transaction_id : ''}`,
                value: this.booking.total_cost,
                label: 'booking',
                currency: this.booking.currency,
                product_ids: `v${this.package?.venue_id}va${this.package?.activity_id}ap${this.package?.package_id}p`,
                category: 'conversion',
                action: 'submitted'
            });

            /*if (typeof fbq !== 'undefined') {
                fbq('track', 'Purchase', {
                    value: this.booking.total_cost,
                    currency: this.booking.currency,
                    content_category: 'booking',
                    content_type: 'product',
                    contents: [
                        {
                          id: `v${this.package.venue_id}va${this.package.activity_id}ap${this.package.package_id}p`,
                          quantity: this.booking.qty_requested
                        }
                    ],
                    product_ids: `v${this.package.venue_id}va${this.package.activity_id}ap${this.package.package_id}p`
                });
            }*/

            if (!this.package.booking_fields || !this.package.booking_fields.length) this.redirect(this.booking);
            else this.setStep.emit();
        }, error: (err) => {
            console.log(ev, err);
            if (ev) ev.complete('fail');

            if (err.type) this.toastr.error(err.message);
            else if (err.error && err.error.errors) err.error.errors.forEach(item => this.toastr.error(item.message));
            else this.toastr.error('Payment Unsuccessful, please check your details and try again');
        }});
    }

    public removePromo(): void {
        this.updateField.emit({ field: 'promo', value: null });

        this.applyPromo(null);
    }

    public setZip(): void {
        const value = this.form.get('card_zip').value;

        this.card.update({
            value: { postalCode: value}
        });
    }

    public removeVoucher(index: number): void {
        const vouchers = JSON.parse(JSON.stringify(this.vouchers));

        vouchers.splice(index, 1);

        this.updateField.emit({ field: 'vouchers', value: vouchers });
    }

    public applyPromoVoucher(field = 'promocode', code = '', showError = true): void {
        if (this.promoLoading || (!this.promocode.trim() && !code)) return;

        const invalid = { voucher: '', promo: '' };

        if((code || this.promocode).trim().length < 1){
            return; //no code to apply
        }
        this.promoLoading = true;

        this.http.get('/promos', {
            params: {
                [field]: (code || this.promocode).trim(),
                no_intercept_response: '1'
            }
        }).pipe(
            tap(res => {
                const promocode = res.body.promos[0];

                const date = format(this.date, 'yyyy-MM-dd');
                //const day = moment(this.date).format('dddd');
                const day = format(this.date, 'dddd');
                /*if(promocode.start_date && promocode.start_date > date) return invalid.promo = 'Promocode not valid yet';
                if(promocode.start_date && promocode.end_date < date) return invalid.promo = 'Promocode no longer valid';
*/
                if (promocode.exclusions && promocode.exclusions.length) {
                    if(!this.date) this.date = new Date()
                    //const date = moment(this.date).format('YYYY-MM-DD');

                    const excluded = promocode.exclusions.reduce((acc, item) => {
                        if (acc) return acc;

                        return item.days.includes(day) || item.date === date;
                    }, false);

                    if (excluded) return invalid.promo = 'Promo code is not valid on this day';
                }

                const promo = this.applyPromo(res.body.promos[0]);

                if (!promo) return invalid.promo = 'Promo code is invalid';

                this.updateField.emit({ field: 'promo', value: promo });
                this.toastr.success('Discount applied');
                this.promocode = '';
            }),
            catchError((err) => {
                invalid.promo = 'Promocode is invalid';

                const fields = [
                    'price_options',
                    'component_id',
                    'session_id',
                    'package_id',
                    'venue_id',
                    'activity_id',
                    'booking_id',
                    'start_date',
                    'qty_requested',
                    'price',
                    'pax'
                ];

                const components = this.http.enocodeJSON({
                    components: this.booking.components.map(item => ({
                        ...pick(item, fields),
                        price_options: this.priceOptions.map(val => omit(val, ['sessions', 'dow'])),
                        total_cost: this.total
                    })),
                    no_intercept_response: '1'
                }).split('&').reduce((acc, item) => {
                    const [key, value] = item.split('=');
                    return { ...acc, [key]: value };
                }, {});

                if (field === 'promo_id') return of(null);

                if((code || this.promocode.trim()).length < 1){
                    return of(null);
                }
                return this.http.get('/vouchers', {
                    params: {
                        _form: 'iqvalidate',
                        redemption_code: (code || this.promocode).trim(),
                        currency: this.booking.currency,
                        booking_id: this.booking ? this.booking.booking_id : null,
                        value: this.total.toString(),
                        no_intercept_response: '1',
                        widget: '1',
                        ...components
                    }
                });
            }),
            tap(res => {
                if (!res || !res.body.validation) return;

                invalid.promo = '';

                if (res.body.validation && !res.body.validation.valid) {
                    return invalid.voucher = res.body.validation ? res.body.validation.message : 'Voucher is invalid';
                }

                this.toastr.success('Voucher applied');

                const vouchers = uniqBy([...this.vouchers, ...res.body.vouchers], 'redemption_code');

                this.updateField.emit({ field: 'vouchers', value: vouchers });
            }),
            finalize(() => {
                if (invalid.voucher && showError) this.toastr.error(invalid.voucher || 'Invalid promo or voucher code');

                this.promoLoading = false;
            })
        ).subscribe();
    }

    public getTotals(): void {
        const flexiSelected = this.form?.get('flexi').value;

        const flexi = this.flexi && flexiSelected ? this.flexi : 0;

        const extras = this.extras.reduce((acc, item)=>{return acc + (item.qty_requested??0) *(this.promo ? (item.price_calc || item.price) : item.price)},0);;

        this.total = this.priceOptions.reduce((acc, item) => {
            return acc + (item.qty_requested * (this.promo ? (item.price_calc || item.price) : item.price));
        }, 0) + flexi + extras;

        
        this.checkDeposit(flexi, extras);

        let total = this.selectedOption ? this.selectedOption.price : this.total;

        if (this.vouchers.length) total -= +this.vouchers.reduce((acc, item) => acc + +item.balance_value, 0);
        if (total < 0) total = 0;

        if (total > 0) this.fee = this.payments.fees.reduce((acc, item) => item.min < total ? item.fee : acc, 0);
        else this.fee = 0;

        if (!this.paymentRequestInstance) return;

        this.paymentRequestInstance.update({
            total: {
                label: `${this.package.name}`,
                amount: round(this.dueNow * 100)
            }
        });
    }

    private setFlexiPrice(): void {
        if (
            isBefore(addWeeks(this.date, -2), new Date()) || isSameDay(addWeeks(this.date, -2), new Date()) ||
            /*moment(this.date).subtract(2, 'weeks').isSameOrBefore(moment()) ||*/
            !this.company.cms_config.flexi.enabled ||
            !+this.venue.flexi_enabled ||
            (
                this.company.company_id === '2' && (
                    ['2', '34', '144','6','19','294','295'].includes(this.package.activity_id) ||
                    +this.package.price >= 100
                )
            )
        ) return;

        const venueOptions = this.venue.flexi_options?.length ? this.venue.flexi_options : null;

        this.flexi = (venueOptions ||  this.company.cms_config.flexi.options || []).reduce((acc, item) => {
            if (+item.min_pax <= +this.booking.qty_requested && +item.price) return item.price;

            return acc;
        }, 0);
    }

    private startTimer(): void {
        if (this.timerSub) this.timerSub.unsubscribe();

        this.modalVisible = false;

        this.timer = `${('0' + Math.floor(+this.duration * 60 / 60)).slice(-2)}:${('0' + Math.floor(+this.duration * 60 % 60)).slice(-2)}`

        const timerDuration = this.duration * 60 - 1;

        this.timerSub = interval(1000).subscribe(duration => {
            const currentTime = timerDuration - duration;
            const minutes = ('0' + Math.floor(currentTime / 60)).slice(-2);
            const seconds = ('0' + Math.floor(currentTime % 60)).slice(-2);

            if (currentTime <= 0) this.refreshAvailability();
            else this.timer = `${minutes}:${seconds}`;
        });
    }

    private refreshAvailability(): void {
        this.timerSub.unsubscribe();

        this.modalVisible = true;
    }

    private redirect(booking: any): void {
        let url = `https://rezbot.com/${this.booking.guest_key}/${this.booking.booking_id}`;

        if (this.booking.company_id == 2) {
            const themeUrl = this.brand && this.brand.theme_website ? this.brand.theme_website : 'https://geronigo.com';
            url = `${themeUrl}/en/confirmation/bookingID-${this.booking.booking_id}_guestKey-${this.booking.guest_key}`;
        }

        if(this.http.isDev || this.http.isTestMode){
            console.log('no redirect to '+url);
            return;
        }

        window.location.href = url;
    }

    private initPayments(): void {
        this.initPaypal();
        this.initStripe();

        setTimeout(() => {
            this.showCard = !(this.profiles.includes('paypal') || this.showPaymentRequest);

            this.paymentMethods = [
                this.profiles.includes('stripe'),
                this.profiles.includes('paypal'),
                this.showPaymentRequest
            ].filter(Boolean).length;
        });
    }

    private applyPromo(promo: any): any {
        const qty = this.priceOptions.reduce((acc, item) => acc + item.qty_requested, 0);
        const res = applyPromo(promo, this.date, this.venue, this.package, this.session, this.priceOptions, qty);

        if (typeof res === 'string') {
            this.toastr.error(res);

            return;
        }

        
        //apply to extras
        if(this.extras){
            this.extras.map(extra=>{
                return applyPromo(promo, this.date, this.venue, extra, this.session, extra.price_options, extra.qty_requested)
                });
        }

        this.updateField.emit({ field: 'priceOptions', value: res });

        if (promo) return { promo, id: promo.promo_id, code: promo.promocode, desc: promo.desc };
    }

    private initPaypal(): void {
        if (!this.profiles.includes('paypal')) return;
        if(!this.payments.paypal || typeof paypal === undefined) {
            setTimeout(()=>this.initPaypal(), 250);
            return;
        }

        
        if(!paypal.Buttons){
            console.log('paypal error');
            return; 
        }

        paypal.Buttons({
            createOrder: (_, actions) => {
                this.loading = true;

                return actions.order.create({
                    purchase_units: [{
                        amount: { value: this.dueNow },
                        description: this.booking.booking_id
                    }]
                });
            },
            onApprove: (data, actions) => {

                from(actions.order.authorize()).subscribe({next:res => {
                    this.makeTransaction({ id: data.orderID }, 'paypal', null, true);
                },error:(err)=>{
                    if (err.message) this.toastr.error("Paypal Payment failed Authentication.\n. If the problem persists, log into your paypal account, and check your account doesn`'t need validating.");
                    this.loading = false;
                    this.http.post('/note',{'booking_id':this.booking?.booking_id,'message':`Paypal Payment failed Authentication`,'viewed':1,'type':'admin'})
                    err.message = `Paypal Payment failed Authentication:\nbooking_id ${this.booking?.booking_id} ${err.message}`;
                    throwError(()=>err).subscribe()
                }});
            },
            onCancel: () => this.loading = false,
            onError: error => {
                this.loading = false;
            }
        }).render(this.paypalEl.nativeElement);
    }

    private getToken(): Observable<any> {
        if (this.dueNow <= 0) return of(null);

        return from(this.payments.stripe.createToken(this.card)).pipe(
            switchMap(({ token, error }: any) => {
                if (error) return throwError(()=>error);

                return of(token);
            })
        );
    }

    private initStripe(): void {
        if (!this.profiles.includes('stripe')) return;

        if(!this.payments?.stripe){
            setTimeout(()=>this.initStripe(),500); return;
        }

        this.card = this.payments.elements.create('card', {
            hidePostalCode: true,
            style: {
                base: {
                    lineHeight: '18px',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    '::placeholder': {
                        color: 'rgba(110, 110, 110, 0.6)',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '18px'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            }
        });

        this.card.mount(this.cardEl.nativeElement);

        this.initPaymentRequest();
    }

    private async initPaymentRequest(): Promise<any> {
        this.paymentRequestInstance = this.payments.stripe.paymentRequest({
            country: 'GB',
            currency: this.venue.currency.toLowerCase(),
            total: {
                label: `${this.venue.name}, ${this.package.name}`,
                amount: round(this.dueNow * 100),
            },
            displayItems: this.priceOptions.filter(item => item.qty_requested).map(item => ({
                label: item.name,
                amount: round(item.price * item.qty_requested * 100)
            })),
            requestPayerName: true,
            requestPayerEmail: true,
            requestPayerPhone: true
        });

        this.paymentButton = this.payments.elements.create('paymentRequestButton', {
            paymentRequest: this.paymentRequestInstance,
            style: {
                paymentRequestButton: {
                    type: 'buy',
                    theme: 'light',
                    height: '45px'
                }
            }
        });

        this.showPaymentRequest = await this.paymentRequestInstance.canMakePayment();

        if (!this.showPaymentRequest) return;


        this.paymentButton.mount(this.paymentRequestEl.nativeElement);

        this.paymentRequestInstance.on('token', async (ev) => {
            if (ev.error) return ev.complete('fail');
            this.makeTransaction(ev.token, 'card', ev);
        });
    }

    public disableDoubleClick(event){
        if(!this.clickAllowed){
            event.stopPropagation();
            return;
        } 
        this.clickAllowed = false;
        setTimeout(()=>this.clickAllowed = true,2000); //not available for 2 seconds to avoid doubleclicking
    }

    private voucherTransaction(): Observable<any> {
        if (!this.vouchers.length) return of(null);

        let amount = (this.selectedOption ? this.selectedOption.price : this.total);

        return concat(
            ...this.vouchers.map(item => {
                const totalAmount = amount < +item.balance_value ? amount : item.balance_value;

                amount -= totalAmount;

                return this.http.post('/transactions', {
                    booking_id: this.booking.booking_id,
                    currency: this.booking.currency,
                    payment_method: 'voucher',
                    redemption_code: item.redemption_code,
                    total_amount: totalAmount
                })
            })
        ).pipe(last(),
        catchError((e)=>{return of(null)}) //ignore vouchertransaction error);
        );
    }

    public get isTestMode():boolean{
        return this.http.isTestMode;
    }

    public get mode():string{
        return this.http.mode;
    }

    private checkDeposit(flexi = 0, extras = 0): any {
        const people = this.priceOptions.reduce((acc, item) => acc + item.qty_requested, 0);
        
        const activity = JSON.parse(this.memoryStorage.getItem('activities')).find(it=>this.package.activity_id==it.activity_id);
        const pay_profile = JSON.parse(this.memoryStorage.getItem('pay_profile'));

        if(!+this.package.accept_deposits){
            if(this.deposit_fix){
                //check if company settings exist
                if(pay_profile.deposit?.deposit || pay_profile.deposit?.deposit_lead|| pay_profile.deposit?.deposit_min || pay_profile.deposit?.deposit_type || pay_profile.deposit?.payment_due){
                    this.package.accept_deposits = true;
                    if(!this.package.deposit) this.package.deposit = pay_profile.deposit.deposit;
                    if(!this.package.deposit_lead) this.package.deposit_lead = pay_profile.deposit.deposit_lead;
                    if(!this.package.deposit_min) this.package.deposit_min = pay_profile.deposit.deposit_min;
                    if(!this.package.deposit_type) this.package.deposit_type = pay_profile.deposit.deposit_type;
                    if(!this.package.payment_due) this.package.payment_due = pay_profile.deposit.payment_due;
                }
            }else{
                this.package.deposit = null;
                this.package.deposit_lead = null;
            }
        }

        if (!+this.package.accept_deposits || this.company.company_id !== '2' && (!+this.package.deposit || !this.package.deposit_lead)) return;

        if(this.company.company_id == '2' && activity?.deposit_profile == -1) return;

        let depositProfile = null; 
        if(this.company?.company_id == 2){
            depositProfile = pay_profile.deposit_profile[activity?.deposit_profile];
            if(depositProfile) depositProfile.deposit_type = '1'; //per person

            if(this.package.deposit === null || this.package.deposit_lead === null){
                this.package.deposit = depositProfile?.deposit;
                this.package.deposit_type = '1'; //per person
                this.package.deposit_lead = depositProfile?.deposit_lead;
            }
            this.package.deposit_min = null;
            if(!this.package.deposit_type) this.package.deposit_type = '1'; //per person;
        }

        let totalDepositValue = 0;

        if (this.package.deposit_type === '4') totalDepositValue = this.total * (+this.package.deposit / 100);
        else if (this.package.deposit_type === '3' || this.package.deposit_type === '2') totalDepositValue = +this.package.deposit;
        else totalDepositValue = +this.package.deposit * people;

        if (+this.package.deposit_min && +this.package.deposit_min > totalDepositValue) totalDepositValue = +this.package.deposit_min;

        if (this.total <= totalDepositValue || !totalDepositValue) return;

        //const start = moment(this.date).startOf('day');
        const start = startOfDay(this.date);
        //const today = moment().startOf('day');
        const today = startOfDay(new Date());
        //const deadline = start.clone().subtract(+this.package.deposit_lead, 'days').startOf('day');
        const deadline = startOfDay(addDays(start, -this.package.deposit_lead));
        //const dueBy = this.package.payment_due ? start.clone().subtract(+this.package.payment_due, 'days') : deadline;
        const dueBy = this.package.payment_due ? addDays(start, -this.package.payment_due) : deadline;

        //if (today.isAfter(deadline)) return;
        if (isAfter(today, deadline)) return;

        console.log(this.date, dueBy, start, -this.package.payment_due, deadline)

        //if (start.isSame(dueBy)) this.dueBy = 'Remaining balance due on arrival';
        if (isSameDay(start,dueBy)) this.dueBy = 'Remaining balance due on arrival';
        else if (isSameDay(today,dueBy)) this.dueBy = 'Remaining balance due today';
        //else this.dueBy = `Remaining balance due by ${dueBy.format('Do MMM yyyy')}`;
        else this.dueBy = `Remaining balance due by ${format(dueBy,'do MMM yyyy')}`;

        this.options = [
            { val: 'full', name: 'Pay in Full', price: this.total },
            { val: 'deposit', name: 'Pay Deposit', price: round(totalDepositValue + flexi + extras, 2) }
        ];
    }
}
