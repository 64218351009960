import { FactoryProvider, InjectionToken, PLATFORM_ID, Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

/* removed local storage. using session storage instead as none of the data needs to persist*/

export const MEMORY_STORAGE = new InjectionToken('memoryStorageToken');


@Injectable({
    providedIn: 'root'
})
export class MemoryStorage {
    
    private data :{ [key: string]: string } = {};

    public setItem(key:string,val:string): void{
        this.data[key] = val;
    }

    public getItem(key:string): string{
        return this.data[key] ?? null;
    }

    public removeItem(key:string): void{
        delete this.data[key];
    }

    public clear(): void{
        this.data = {};
    }

    public get length(): number{
        return Object.keys(this.data).length;
    }

    public key(index: number):string|null{
        let keys = Object.keys(this.data);
        if(index >= keys.length) return null;
        return Object.keys(this.data)[index];
    }
}


export function memoryStorageFactory(platformId: Object): Storage {
    return new MemoryStorage();
}

export const memoryStorageProvider: FactoryProvider = {
    provide: MEMORY_STORAGE,
    useFactory: memoryStorageFactory,
    deps: [PLATFORM_ID]
};

export const MEMORY_STORAGE_PROVIDERS = [
    memoryStorageProvider
];